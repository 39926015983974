@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../base/variables';
@import './../helpers/mixins';


.block-experiences-section-wrap {
  background-color: $green-50;
  position: relative;
  padding: 64px 0 48px;
  overflow: hidden;
  h2 {
    margin-bottom: 24px;
  }
  .hodnotenia-wrap {
    margin: 24px -12px;
    display: flex;
    //align-items: center;
    flex-wrap: wrap;
    .hodnotenia-item {
      background-color: $white;
      padding: 24px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      margin: 0 12px;
      text-align: center;
      flex: 0 0 calc(50% - 24px);
      margin-bottom: 24px;
      &:nth-child(3) {
        flex: 0 0 calc(100% - 24px);
      }
      .title {
        color: $green;
        font-size: toRem(24);
      }
      .subtitle {
        font-size: toRem(14);
      }
    }
  }
  .gallery-wrap {
    display: flex;
    align-items: center;
    overflow: auto;
    margin-bottom: 64px;
    margin-left: calc($grid-gutter-width / 2 * -1);
    margin-right: calc($grid-gutter-width / 2 * -1);

    .image-wrap {
      width: 240px;
      height: 160px;
      flex: 0 0 240px;
      margin: 0 12px;
      filter: drop-shadow(0px 5px 15px rgba(153, 204, 102, 0.5));
      border-radius: 10px;
      overflow: hidden;
      img {
        @include objetfit;
      }
    }
  }
  .button-wrap {
    text-align: center;
  }
  @include media-breakpoint-up(sm) { 
    .gallery-wrap {
      margin-left: calc((((100vw - #{get-breakpoints("sm")})/2) + ($grid-gutter-width / 2))* -1 );
      margin-right: calc((((100vw - #{get-breakpoints("sm")})/2) + ($grid-gutter-width / 2))* -1 );
    }
  }
  @include media-breakpoint-up(md) { 
    .gallery-wrap {
      margin-left: calc((((100vw - #{get-breakpoints("md")})/2) + ($grid-gutter-width / 2))* -1 );
      margin-right: calc((((100vw - #{get-breakpoints("md")})/2) + ($grid-gutter-width / 2))* -1 );
    }
  }
  @include media-breakpoint-up(lg) { 
    padding: 96px 0;
    background-image: url('./../../assets/img/icon2.svg');
    background-repeat: no-repeat;
    background-position: center right;
    .relative {
      position: relative;
      height: 100%;
    }
    .hodnotenia-wrap {
      margin: 48px -12px 24px;
      .hodnotenia-item {
        .title {
          font-size: toRem(36);
        }
      }
    }
    .gallery-wrap {
      position: absolute;
      top: calc(-96px - 40px);
      bottom: calc(-96px - 40px);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      overflow: hidden;
      .image-wrap {
        width: 360px;
        height: 210px;
        flex: 0 0 auto;
        margin: 20px 12px;

      }
    }
    .button-wrap {
      text-align: left;
    }
  }
  @include media-breakpoint-up(xl) { 
    .hodnotenia-wrap {
      flex-wrap: nowrap;
      .hodnotenia-item {
        flex: 0 0 auto;
        &:nth-child(3) {
          flex: 0 0 auto;
        }
        .title {
          font-size: toRem(36);
        }
      }
    }
  }
}
